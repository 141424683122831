// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Collagecreator2 from "../../blocks/collagecreator2/src/Collagecreator2";
import Slideshowcreator2 from "../../blocks/slideshowcreator2/src/Slideshowcreator2";
import Notifications from "../../blocks/notifications/src/Notifications";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Googledocsintegration5 from "../../blocks/googledocsintegration5/src/Googledocsintegration5";
import Tracxnsectorresearchgooglewikipediaintegration3 from "../../blocks/tracxnsectorresearchgooglewikipediaintegration3/src/Tracxnsectorresearchgooglewikipediaintegration3";
import Pixabaypexelunsplashflikrshutterstockintegration3 from "../../blocks/pixabaypexelunsplashflikrshutterstockintegration3/src/Pixabaypexelunsplashflikrshutterstockintegration3";
import Googleslideintegration4 from "../../blocks/googleslideintegration4/src/Googleslideintegration4";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";


const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Collagecreator2:{
 component:Collagecreator2,
path:"/Collagecreator2"},
Slideshowcreator2:{
 component:Slideshowcreator2,
path:"/Slideshowcreator2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Googledocsintegration5:{
 component:Googledocsintegration5,
path:"/Googledocsintegration5"},
Tracxnsectorresearchgooglewikipediaintegration3:{
 component:Tracxnsectorresearchgooglewikipediaintegration3,
path:"/Tracxnsectorresearchgooglewikipediaintegration3"},
Pixabaypexelunsplashflikrshutterstockintegration3:{
 component:Pixabaypexelunsplashflikrshutterstockintegration3,
path:"/Pixabaypexelunsplashflikrshutterstockintegration3"},
Googleslideintegration4:{
 component:Googleslideintegration4,
path:"/Googleslideintegration4"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
ThemeBlock:{
 component:ThemeBlock,
path:"/ThemeBlock"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;